<template>
  <div>
    <component
      :is="ghsComponent"
      :param.sync="popupParam"
    />
  </div>
</template>

<script>
export default {
  name: 'chem-ghs',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        subtabName: '',
        plantCd: null,
      }),
    },
    chemData: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        mdmChemMaterialId: '',  // 화학자재 일련번호
        materialCd: '',  // 자재코드
        mdmChemReviewId: '',  // 사전검토 일련번호
        deliveryVendorCd: '',  // 공급업체 코드
        // mfgVendorCd: '',  // (사용안함) 제조업체 코드
        propertiesStateCd: null,  // 성상-공통코드
        fugacity: '',  // 비산성(고체)
        boilPoint: '',  // 끊는점(액체,기체)
        meltingPoint: '',  // 녹는점
        moleWeight: '',  // 분자량
        usageCd: null,  // 용도-회사별 공통코드
        usageEtc: '',  // 용도 기타
        mixFlag: '',  // 물질구성(단일 N/혼합 Y)
        casNo: '',  // CAS No.
        // hsNo: '',  // (사용안함) HS번호
        // specificGravity: '',  // (사용안함) 비중
        // serialNo: '',  // (사용안함) 고유번호(유독물번호or고시번호)
        // keNo: '',  // (사용안함) KE 번호
        // storagePlace: '',  // (사용안함) 보관장소
        productCategoryCd: null,  // 제품구분
        keepFacilityFlag: 'N',  // 보관시설 유무
        maxKeepAmount: '',  // 최대보관량
        storageFacilityFlag: 'N',  // 저장시설 유무
        maxStorageAmount: '',  // 최대저장량
        manufacturingFacilitiesFlag: 'N',  // 제조 사용시설 여부
        nanoManufacturingFlag: 'N',  // 나노물질 제조 여부
        nanoIncomeFlag: 'N',  // 나노물질 수입 여부
        nanoNoneApplicableFlag: 'N',  // 나노물질 해당없음 여부
        nanoUnknownFlag: 'N',  // 나노물질 모름 여부
        inputDissolutionTank: '',  // 투입 용해조
        productManufClassCd: null,  // 제품제조구분 코드
        chemIngredientsCd: '',  // 구성성분 작성근거
        useFlag: 'Y',  // 사용여부
        licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
        dangerFlag: 'N',  // 위험물 여부Y/N
        psmFlag: 'N',  // PSM 대상 여부
        makeFlag: 'N',  // 제품분류(제조)
        impFlag: 'N',  // 제품분류(수입)
        buyFlag: 'N',  // 제품분류(구매)
        usingFlag: 'N',  // 제품분류(사용)
        expFlag: 'N',  // 제품분류(수출)
        salesFlag: 'N',  // 제품분류(판매)
        toxicPoisonFlag: 'N',  // 유해화학물질포함여부(유독물질)
        toxicPermitFlag: 'N',  // 유해화학물질포함여부(허가물질)
        toxicLimitFlag: 'N',  // 유해화학물질포함여부(제한물질)
        toxicProhibitFlag: 'N',  // 유해화학물질포함여부(금지물질)
        remarks: '',  // 비고
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        // specialCtype: '',  // (사용안함) 발암성 C-위험성평가용
        // specialRtype: '',  // (사용안함) 생식독성 R-위험성평가용
        // specialMtype: '',  // (사용안함) 생식세표변이원성 M-위험성평가용
        // rcode: '',  // (사용안함) 위험문구(R-phrase)-위험성평가용
        // hcode: '',  // (사용안함) 유해위험문구(H-code)-위험성평가용
        // cmrFlag: '',  // (사용안함) CMR물질여부-위험성평가용
        // boilingPoint: '',  // (사용안함) 끓는점-위험성평가용
        // hrCodeGrade: '',  // (사용안함) 위험문구/유해위험문구 등급-위험성평가용
        // maleficenceForm: '',  // (사용안함) 유해성 발생형태-위험성평가용
        // maleficenceValue: '',  // (사용안함) 유해성 측정값-위험성평가용
        // maleficenceGrade: '',  // (사용안함) 비산성 등급-위험성평가용
        // maleficenceType: '',  // (사용안함) 비산/휘발성 구분-위험성평가용
        // dayVolume: '',  // (사용안함) 하루취급량
        // dayVolumeUnit: '',  // (사용안함) 하루취급량 단위
        // ghsFlag: '',  // (사용안함) GHS 분류코드
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      // tab: 'chemGhs1',
      // tabItems: [
      //   { name: 'chemGhs1', icon: 'groups', label: '1. 구성물질별 GHS 분류데이터 (국가별)', component: () => import(`${'./chemGhs1.vue'}`) },
      //   { name: 'chemGhs2', icon: 'list', label: '2. 제품 GHS 혼합분류 결과', component: () => import(`${'./chemGhs2.vue'}`) },
      //   { name: 'chemGhs3', icon: 'list', label: '3. 제품 GHS 분류근거 DB', component: () => import(`${'./chemGhs3.vue'}`) },
      // ],
      ghsComponent: () => import(`${'./chemGhs2.vue'}`)
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
    },
  }
};
</script>